import React from 'react'
import { Link } from 'gatsby'
import {Helmet} from 'react-helmet'

import titleCard from '../../static/the-golden-rule.png'

const cYear = new Date().getFullYear()

class Masthead extends React.Component {
  render() {
    const siteTitle = this.props.siteTitle
    let topknot

    topknot = (
      <h3
        style={{
          lineHeight: `2rem`,
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <Link to={'/'}>
          <img
            src={titleCard}
            width="144"
            height="144"
            alt={siteTitle}
          />
        </Link>
      </h3>
    )

    return (
      <header
        style={{
          textAlign: `center`,
        }}
      >
        <Helmet defaultTitle={siteTitle} titleTemplate={'%s - ' + siteTitle}>
          <html lang="en" />
          <meta name="copyright" content={cYear} />
          <meta name="twitter:site" content={this.props.siteTwitter} />
          <meta name="og:type" content="website" />
          <meta name="og:site_name" content={siteTitle} />
          <meta name="description" content={this.props.description} />
        </Helmet>
        {topknot}
      </header>
    )
  }
}

export default Masthead
