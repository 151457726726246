import React from 'react'

import Footer from '../components/Footer'
import Masthead from '../components/Masthead'

import 'typeface-source-sans-pro'
import 'typeface-source-serif-pro'

import './layout.css'

export default function Layout({ children }) {
  return (
    <div className='container'>
      <Masthead
        description='A Midwestern grandma moves to a SoCal retirement community—and finds herself terrorized by a clique of 80something mean girls.'
        siteTitle='The Golden Rule'
        siteTwitter='@realgoldenrule'
      />
      {children}
      <hr />
      <footer>
        <Footer
          siteEmail='sharon@goldenrule.world'
          siteTitle='The Golden Rule'
          siteTwitter='@realgoldenrule'
          siteYouTube='UCgbiY3BS7JR0F3sfUSeW0vQ'
        />
      </footer>
    </div>
  )
}
