import React from 'react'
import { Link } from 'gatsby'
// eslint-disable-next-line
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
// https://fontawesome.com/icons?d=gallery

class Footer extends React.Component {
  render() {
    const siteEmail = this.props.siteEmail
    const siteTwitter = this.props.siteTwitter
    const siteYouTube = this.props.siteYouTube

    return (
      <div>
        <section>
          <h4>Do you want updates and secret prizes?</h4>
          <div>(for example, maybe a wine tote)</div>
          {/* <!-- Begin Mailchimp Signup Form --> */}
          <div id='mc_embed_signup'>
            <form action='https://world.us13.list-manage.com/subscribe/post?u=cc7c830b9a3db45ac8071f979&amp;id=f2a4720d4b' method='post' id='mc-embedded-subscribe-form' name='mc-embedded-subscribe-form' target='_blank'>
              <div id='mc_embed_signup_scroll'>
                <input type='email' defaultValue='' name='EMAIL' className='email' id='mce-EMAIL' placeholder='email address' required />
                {/*
                  <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                */}
                <div style={{position: 'absolute', left: '-5000px',}} aria-hidden='true'><input type='text' name='b_cc7c830b9a3db45ac8071f979_f2a4720d4b' tabIndex='-1' defaultValue='' /></div>
                <div className='clear'><input type='submit' value='Sign Up' name='subscribe' id='mc-embedded-subscribe' className='button' /></div>
              </div>
            </form>
          </div>
          {/* <!--End mc_embed_signup--> */}
        </section>
        <section>
          <ul className="footer-links">
            <li>
              <Link to="/about/">
                <span>
                  <FontAwesomeIcon icon={faFileAlt} />
                </span>
                {` `}
                about
              </Link>
            </li>

            <li>
              <a href={`mailto:${siteEmail}`}>
                <span>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                {` `}
                {siteEmail}
              </a>
            </li>

            <li>
              <a
                href={`https://www.facebook.com/${siteTwitter.substring(1)}`}
                aria-label='Facebook'
              >
                <span className='facebook'>
                  <FontAwesomeIcon icon={faFacebookF} />
                </span>
                {` `}
                {siteTwitter}
              </a>
            </li>

            <li>
              <a
                href={`https://www.instagram.com/${siteTwitter.substring(1)}/`}
                aria-label='Instagram'
              >
                <span className='instagram'>
                  <FontAwesomeIcon icon={faInstagram} />
                </span>
                {` `}
                {siteTwitter}
              </a>
            </li>

            <li>
              <a
                href={`https://twitter.com/${siteTwitter.substring(1)}`}
                aria-label="Twitter"
              >
                <span className='twitter'>
                  <FontAwesomeIcon icon={faTwitter} />
                </span>
                {` `}
                {siteTwitter}
              </a>
            </li>

            <li>
              <a
                href={`https://www.youtube.com/channel/${siteYouTube}`}
                aria-label="YouTube"
              >
                <span className='youtube'>
                  <FontAwesomeIcon icon={faYoutube} />
                </span>
                {` `}
                youtube
              </a>
            </li>
          </ul>
        </section>
      </div>
    )
  }
}

export default Footer
